import { type LoaderFunctionArgs } from 'react-router';
import { getPartnerProducts } from '@apps/admin-portal-web/src/actions/partner-products';
import { type LoaderData } from '@apps/admin-portal-web/src/types/loader';
import { getClaims } from '@apps/admin-portal-web/src/utils/cookies';
import { createSearchParams } from 'react-router-dom';

export async function partnerProductsLoader({ params, request }: LoaderFunctionArgs) {
    const auth = getClaims();
    if (!auth) {
        throw new Error('no auth claims found');
    }

    const query: { archived?: string } = {};
    const url = new URL(request.url);
    const archived = url.searchParams.get('archived');
    if (archived !== null) {
        query.archived = archived;
    }

    const componentID = params.componentID as string;
    const products = await getPartnerProducts(componentID, createSearchParams(query));

    return { products, componentID };
}

export type PartnerProductsLoader = LoaderData<typeof partnerProductsLoader>;
