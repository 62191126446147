import { type LoaderFunctionArgs } from 'react-router';
import {
    getSimulationRun,
    getApplicationsReport,
    getSegmentsReport,
    getFunnelRateReport,
    getUniqueVolumeChangeReport,
    getResultsPerProductReport,
    getExperimentRunProgressReport,
    getExperimentGraphs,
} from '@apps/admin-portal-web/src/actions/simulation';
import { type LoaderData } from '@apps/admin-portal-web/src/types/loader';
import { getClaims } from '@apps/admin-portal-web/src/utils/cookies';
import { parseSegmentsResponse } from './utils/segments';
import { parseFunnelResponse } from './utils/funnel';
import { parseExperimentReport } from './utils/experiment';

export async function simulationRunDetailLoader({ params }: LoaderFunctionArgs) {
    const auth = getClaims();
    if (!auth) {
        throw new Error('no auth claims found');
    }

    const workspace = params.workspace as string;
    const componentId = params.componentID as string;
    const runId = params.runId as string;

    const run = await getSimulationRun(runId, componentId, workspace);

    let progress;

    let experimentReportPromise;
    let applicantSegmentsPromise;
    let funnelPromise;
    let totalApplicaitonsPromise;
    let uniqueVolumenPromise;

    if (run.status === 'active') {
        progress = await getExperimentRunProgressReport(runId, componentId, workspace);
    } else if (run.status === 'completed') {
        const experimentGraphs = await getExperimentGraphs(run.experiment?.id || '', componentId, workspace);

        experimentReportPromise = getResultsPerProductReport(runId, componentId, workspace).then(
            parseExperimentReport(experimentGraphs)
        );
        applicantSegmentsPromise = getSegmentsReport(runId, componentId, workspace).then(parseSegmentsResponse);
        funnelPromise = getFunnelRateReport(runId, componentId, workspace).then(parseFunnelResponse);
        totalApplicaitonsPromise = getApplicationsReport(runId, componentId, workspace);
        uniqueVolumenPromise = getUniqueVolumeChangeReport(runId, componentId, workspace);
    }

    return {
        applicantSegmentsPromise,
        componentId,
        funnelPromise,
        progress,
        run,
        experimentReportPromise,
        totalApplicaitonsPromise,
        uniqueVolumenPromise,
    };
}

export type SimulationRunDetailLoader = LoaderData<typeof simulationRunDetailLoader>;
