import { ProductFormDataType, type PartnerProductType } from '@apps/admin-portal-web/src/types/partner-products';
import { transport } from './transport';
import { pathEscape } from '../utils/pathEscape';

export function getPartnerProducts(componentId: string, query: URLSearchParams) {
    return transport.get<PartnerProductType[]>(`/api/partner-products?${query ? query.toString() : ''}`, {
        componentId,
    });
}

export function addPartnerProduct(body: ProductFormDataType, componentId: string) {
    return transport.post<ProductFormDataType, PartnerProductType>('/api/partner-products', body, { componentId });
}

export function editPartnerProduct(body: ProductFormDataType, id: string, componentId: string) {
    return transport.patch<ProductFormDataType, PartnerProductType>(`/api/partner-products/${pathEscape(id)}`, body, {
        componentId,
    });
}

export function archivePartnerProduct(id: string, componentId: string) {
    return transport.post<void, void>(`/api/partner-products/${pathEscape(id)}/archive`, undefined, {
        componentId,
    });
}

export function unarchivePartnerProduct(id: string, componentId: string) {
    return transport.post<void, void>(`/api/partner-products/${pathEscape(id)}/unarchive`, undefined, {
        componentId,
    });
}
