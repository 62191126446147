import { type RoleType, type UserRoleType } from '@apps/admin-portal-web/src/types/authorization';
import { transport } from './transport';
import { pathEscape } from '../utils/pathEscape';

export function getRoles(workspace: string) {
    return transport.get<RoleType[]>(`/api/authorization/roles?`, { workspace });
}

export function getUserRoles(userId: string, workspace: string) {
    return transport.get<UserRoleType[]>(`/api/authorization/users/${pathEscape(userId)}/roles`, { workspace });
}

export function createUserRole(userId: string, roleId: string, workspaceComponentId: string, workspace: string) {
    interface CreateUserRoleRequest {
        roleId: string;
        workspaceComponentId: string;
    }
    return transport.post<CreateUserRoleRequest, UserRoleType>(
        `/api/authorization/users/${pathEscape(userId)}/roles`,
        {
            roleId: roleId,
            workspaceComponentId: workspaceComponentId,
        },
        { workspace }
    );
}

export function removeUserRole(userId: string, userRoleId: string, workspace: string) {
    return transport.delete<void>(`/api/authorization/users/${pathEscape(userId)}/roles/${pathEscape(userRoleId)}`, {
        workspace,
    });
}
