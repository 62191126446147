export const store = <T>(store: Storage, key: string, defaultValue: T) => {
    const get = (): T => {
        const currentValue = store.getItem(key);
        if (currentValue !== null) {
            try {
                return JSON.parse(currentValue);
            } catch {
                return defaultValue;
            }
        }
        return defaultValue;
    };
    const set = (value: T) => {
        store.setItem(key, JSON.stringify(value));
    };
    const clear = () => {
        store.removeItem(key);
    };

    return { get, set, clear };
};
