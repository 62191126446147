import { useToast } from '@packages/shadcn/ui/use-toast';

export function useCopyToClipboard() {
    const { toast } = useToast();

    return ({ value, label }: { value: string; label: string }) => {
        navigator.clipboard.writeText(value);
        toast({
            title: `${label} copied to clipboard`,
            description: value && value.length < 100 ? value : undefined,
        });
    };
}
