import { Navigate, Outlet } from 'react-router-dom';
import { useClaims } from '@apps/admin-portal-web/src/hooks/useClaims';
import Tracking from '@apps/admin-portal-web/src/utils/tracking';
import { Toaster } from '@apps/admin-portal-web/src/components/Toaster';
import { useLocation, useMatches } from 'react-router';
import { useEffect } from 'react';
import { PageHeader } from '@apps/admin-portal-web/src/types/page-header';
import { EVENTS } from '@apps/admin-portal-web/src/types/tracking';
import { FAQ_PAGES } from '@apps/admin-portal-web/src/types/faq';

export const AuthenticatedLayout = () => {
    /*
    This section extracts the page title of the current route and updates the browser title.
     */
    type HandleType = {
        getPageHeader: (data: unknown) => PageHeader;
        faqPage: (typeof FAQ_PAGES)[keyof typeof FAQ_PAGES];
    };
    const matches = useMatches();
    const lastRouteWithPageHeaderTitle = matches
        .reverse()
        .map(el => {
            const handle = el.handle as HandleType;
            const data = el.data as unknown;
            return el.handle && handle.getPageHeader(data) && handle.getPageHeader(data).title;
        })
        .filter(i => i)[0];

    if (lastRouteWithPageHeaderTitle) {
        document.title = `Lendo Portal - ${lastRouteWithPageHeaderTitle}`;
    } else {
        document.title = 'Lendo Portal';
    }

    /*
    This section tracks "page view" events with GA4.
     */
    const location = useLocation();
    useEffect(() => {
        Tracking.send(EVENTS.PAGE_VIEW, undefined);
    }, [location]);

    /*
    This section redirects to the login page if the user isn't logged in.
    It needs to be here after useLocation, useMatches and useEffect or eslint complains.
     */
    try {
        useClaims();
    } catch {
        // `useClaims()` call failed, redirect to the login page.
        return <Navigate to="/login" />;
    }

    return (
        <div className="h-screen">
            <Outlet />
            <Toaster />
        </div>
    );
};
