import { type RouteObject } from 'react-router-dom';
import { SECTIONS } from '@apps/admin-portal-web/src/types/sidebar';
import { PageHeader } from '@apps/admin-portal-web/src/types/page-header';
import { ErrorComponentBoundary as ErrorBoundary } from '@apps/admin-portal-web/src/pages/ErrorComponentBoundary';
import { FAQ_PAGES } from '@apps/admin-portal-web/src/types/faq';
import { partnerProductsLoader } from './partner-products/loader';
import { productFilteringLoader } from './product-filtering/loader';
import { filteringGraphLoader } from './product-filtering/graph/loader';
import { filteringResultsLoader } from './product-filtering/results/loader';
import { MissingComponent } from './MissingComponent';
import { simulationExperimentsLoader } from './filtering-simulation/experiments/loader';
import { simulationRunsLoader } from './filtering-simulation/runs/loader';
import {
    SimulationExperimentsDetailLoader,
    simulationExperimentsDetailLoader,
} from './filtering-simulation/experiments/detail/loader';
import { SimulationRunDetailLoader, simulationRunDetailLoader } from './filtering-simulation/runs/detail/loader';
import { simulationExperimentsDetailGraphLoader } from './filtering-simulation/experiments/detail/graph/loader';

const productsRoute: RouteObject = {
    path: 'partner-products/:componentID',
    loader: partnerProductsLoader,
    lazy: () => import('@apps/admin-portal-web/src/pages/workspace/component/partner-products'),
    handle: {
        getPageHeader: () => new PageHeader('Product list', SECTIONS.PRODUCTS),
        faqPage: FAQ_PAGES.PARTNER_PRODUCTS,
    },
    ErrorBoundary,
};

const filtersRoute: RouteObject = {
    path: 'product-filtering/:componentID',
    children: [
        {
            index: true,
            loader: productFilteringLoader,
            lazy: () => import('@apps/admin-portal-web/src/pages/workspace/component/product-filtering'),
            handle: {
                getPageHeader: () => new PageHeader('Edit Filters', 'edit'),
                faqPage: FAQ_PAGES.PRODUCT_FILTERING_PRODUCTS,
            },
            ErrorBoundary,
        },
        {
            path: ':productID/graph',
            loader: filteringGraphLoader,
            lazy: () => import('@apps/admin-portal-web/src/pages/workspace/component/product-filtering/graph'),
            handle: {
                getPageHeader: () => new PageHeader('Edit Filters', 'edit'),
                faqPage: FAQ_PAGES.PRODUCT_FILTERING_GRAPH,
            },
            ErrorBoundary,
        },
        {
            path: 'results',
            loader: filteringResultsLoader,
            lazy: () => import('@apps/admin-portal-web/src/pages/workspace/component/product-filtering/results'),
            handle: {
                getPageHeader: () => new PageHeader('View Results', 'fileSearch'),
                faqPage: FAQ_PAGES.PRODUCT_FILTERING_RESULTS,
            },
            ErrorBoundary,
        },
        {
            path: '*',
            lazy: () => import('@apps/admin-portal-web/src/pages/system/notFound'),
        },
    ],
};

const filteringSimulationRoute: RouteObject = {
    path: 'filtering-simulation/:componentID',
    children: [
        {
            index: true,
            path: 'experiments',
            loader: simulationExperimentsLoader,
            lazy: () => import('@apps/admin-portal-web/src/pages/workspace/component/filtering-simulation/experiments'),
            handle: {
                getPageHeader: () => new PageHeader('Simulation Experiments', SECTIONS.FILTERING_SIMULATION),
                faqPage: FAQ_PAGES.FILTERING_SIMULATION_EXPERIMENTS,
            },
            ErrorBoundary,
        },
        {
            path: 'experiments/:experimentId',
            loader: simulationExperimentsDetailLoader,
            lazy: () =>
                import('@apps/admin-portal-web/src/pages/workspace/component/filtering-simulation/experiments/detail'),
            handle: {
                getPageHeader: (data: SimulationExperimentsDetailLoader) =>
                    new PageHeader(data.experiment.name, SECTIONS.FILTERING_SIMULATION),
                faqPage: FAQ_PAGES.FILTERING_SIMULATION_EXPERIMENT,
            },
            ErrorBoundary,
        },
        {
            path: 'experiments/:experimentId/:graphId',
            loader: simulationExperimentsDetailGraphLoader,
            lazy: () =>
                import(
                    '@apps/admin-portal-web/src/pages/workspace/component/filtering-simulation/experiments/detail/graph'
                ),
            handle: {
                getPageHeader: () => new PageHeader('Experiment Graph', SECTIONS.FILTERING_SIMULATION),
                faqPage: FAQ_PAGES.FILTERING_SIMULATION_EXPERIMENT_GRAPH,
            },
            ErrorBoundary,
        },
        {
            path: 'runs',
            loader: simulationRunsLoader,
            lazy: () => import('@apps/admin-portal-web/src/pages/workspace/component/filtering-simulation/runs'),
            handle: {
                getPageHeader: () => new PageHeader('Simulation Runs', 'play'),
                faqPage: FAQ_PAGES.FILTERING_SIMULATION_RUNS,
            },
            ErrorBoundary,
        },
        {
            path: 'runs/:runId',
            loader: simulationRunDetailLoader,
            lazy: () => import('@apps/admin-portal-web/src/pages/workspace/component/filtering-simulation/runs/detail'),
            handle: {
                getPageHeader: ({ run }: SimulationRunDetailLoader) =>
                    new PageHeader(run.experiment?.name || '', 'play'),
                faqPage: FAQ_PAGES.FILTERING_SIMULATION_RESULTS,
            },
            ErrorBoundary,
        },
        {
            path: '*',
            lazy: () => import('@apps/admin-portal-web/src/pages/system/notFound'),
        },
    ],
};

const missingComponent: RouteObject = {
    path: '*',
    Component: MissingComponent,
};

const componentRoutes: RouteObject[] = [productsRoute, filtersRoute, filteringSimulationRoute, missingComponent];

export default componentRoutes;
