import { store } from '@apps/admin-portal-web/src/stores/store';

function isValidInternalUrl(url: string): boolean {
    return url.startsWith('/');
}

export const redirectUrlStore = () => {
    const { get, set, clear } = store<string>(localStorage, 'redirectUrl', '');

    const safeGet = (): string => {
        const value = get();
        if (isValidInternalUrl(value)) {
            return value;
        }
        return '';
    };

    const safeSet = (value: string) => {
        if (isValidInternalUrl(value)) {
            set(value);
        } else {
            clear();
        }
    };

    return { getRedirectUrl: safeGet, setRedirectUrl: safeSet, clearRedirectUrl: clear };
};
