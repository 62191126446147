/*
The events defined here should be described in the Taxonomy document found here:
https://docs.google.com/spreadsheets/d/1-rsutQ2f2Xknkzj6AibtQpeIL_wEjN-84MwhL_nV56U
 */

type ConsentState = 'granted' | 'denied';

export type ConsentOpts = {
    ad_storage: ConsentState;
    ad_user_data: ConsentState;
    ad_personalization: ConsentState;
    analytics_storage: ConsentState;
};

export type PageViewEvent = {
    path?: string;
    title?: string;
    location?: string;
};

export type WorkspaceSwitchEvent = {
    from?: string;
    to: string;
};

type workspaceEvent = {
    workspace: string;
    componentType?: string;
    componentInstance?: string;
};

type workspaceMenuEvent = workspaceEvent & {
    item: string;
    path: string;
};

type fileMenuEvent = workspaceEvent & {
    item: string;
};

export type WorkspaceMenuClickEvent = workspaceMenuEvent;

export type WorkspaceMenuExpandEvent = workspaceMenuEvent;

export type WorkspaceMenuCollapseEvent = workspaceMenuEvent;

export type FileMenuClickEvent = fileMenuEvent;

export type FileMenuExpandEvent = fileMenuEvent;

export type FileMenuCollapseEvent = fileMenuEvent;

export const EVENTS = {
    PAGE_VIEW: 'page_view',
    WORKSPACE_SWITCH: 'workspace_switch',
    WORKSPACE_MENU_CLICK: 'workspace_menu_click',
    WORKSPACE_MENU_EXPAND: 'workspace_menu_expand',
    WORKSPACE_MENU_COLLAPSE: 'workspace_menu_collapse',
    FILE_MENU_CLICK: 'file_menu_click',
    FILE_MENU_EXPAND: 'file_menu_expand',
    FILE_MENU_COLLAPSE: 'file_menu_collapse',
} as const;

export type EventName = (typeof EVENTS)[keyof typeof EVENTS];

export type EventPayloads = {
    [EVENTS.PAGE_VIEW]: PageViewEvent | undefined;
    [EVENTS.WORKSPACE_SWITCH]: WorkspaceSwitchEvent;
    [EVENTS.WORKSPACE_MENU_CLICK]: WorkspaceMenuClickEvent;
    [EVENTS.WORKSPACE_MENU_EXPAND]: WorkspaceMenuExpandEvent;
    [EVENTS.WORKSPACE_MENU_COLLAPSE]: WorkspaceMenuCollapseEvent;
    [EVENTS.FILE_MENU_CLICK]: FileMenuClickEvent;
    [EVENTS.FILE_MENU_EXPAND]: FileMenuExpandEvent;
    [EVENTS.FILE_MENU_COLLAPSE]: FileMenuCollapseEvent;
};

export type EventPayload<T extends keyof EventPayloads> = EventPayloads[T];
