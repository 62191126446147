import { type Env } from '@apps/admin-portal-web/src/types/env';

declare global {
    interface Window {
        injectedEnv: Env | undefined;
    }
}

export const env = {
    ...(import.meta.env.MODE && { MODE: import.meta.env.MODE }),
    ...(import.meta.env.VITE_OKTA_ISSUER && { OKTA_ISSUER: import.meta.env.VITE_OKTA_ISSUER }),
    ...(import.meta.env.VITE_OKTA_CLIENT_ID && { OKTA_CLIENT_ID: import.meta.env.VITE_OKTA_CLIENT_ID }),
    ...(import.meta.env.VITE_SENTRY_DSN && { VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN }),
    ...(import.meta.env.VITE_BUILD_VERSION && { VITE_BUILD_VERSION: import.meta.env.VITE_BUILD_VERSION }),
    ...(import.meta.env.VITE_GA4_MEASUREMENT_ID && { GA4_MEASUREMENT_ID: import.meta.env.VITE_GA4_MEASUREMENT_ID }),
    ...(typeof window !== 'undefined' && window.injectedEnv ? window.injectedEnv : {}),
} as Env;
