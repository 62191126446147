import { WorkspaceComponentInstance, WorkspaceComponentInstanceApi } from '@apps/admin-portal-web/src/types/components';
import { ComponentValueType, SECTIONS } from '@apps/admin-portal-web/src/types/sidebar';
import { snakeToCamelString } from '@apps/admin-portal-web/src/utils/snakeToCamel';

export const parseWorkspaceToApi = (data: WorkspaceComponentInstance) => {
    const output: WorkspaceComponentInstanceApi = {
        id: data.id,
        name: data.name,
        component: data.component,
        description: data.description,
    };

    if (data.component === SECTIONS.FILTERING) {
        output.relations = {
            [snakeToCamelString(SECTIONS.PRODUCTS)]: [data.partnerProductsWorkspaceComponentId].filter(
                i => i
            ) as string[],
        };
    }

    if (data.component === SECTIONS.FILTERING_SIMULATION) {
        output.relations = {
            [snakeToCamelString(SECTIONS.FILTERING)]: [data.filteringSimulationWorkspaceComponentId].filter(
                i => i
            ) as string[],
        };
    }

    return output;
};

export const parseWorkspaceToForm = (data: WorkspaceComponentInstanceApi) => {
    const output: WorkspaceComponentInstance = {
        id: data.id || '',
        name: data.name,
        component: data.component,
        description: data.description,
    };

    if (data.component === SECTIONS.FILTERING) {
        output.partnerProductsWorkspaceComponentId =
            data.relations?.[snakeToCamelString<ComponentValueType>(SECTIONS.PRODUCTS)]?.[0];
    }

    if (data.component === SECTIONS.FILTERING_SIMULATION) {
        output.filteringSimulationWorkspaceComponentId =
            data.relations?.[snakeToCamelString<ComponentValueType>(SECTIONS.FILTERING)]?.[0];
    }

    return output;
};
