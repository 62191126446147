import { type LoaderFunctionArgs } from 'react-router';
import { getFilterRuleProducts } from '@apps/admin-portal-web/src/actions/product-filtering';
import { type LoaderData } from '@apps/admin-portal-web/src/types/loader';
import { getClaims } from '@apps/admin-portal-web/src/utils/cookies';

export async function productFilteringLoader({ params }: LoaderFunctionArgs) {
    const auth = getClaims();
    if (!auth) {
        throw new Error('no auth claims found');
    }

    const componentID = params.componentID as string;
    const products = await getFilterRuleProducts(componentID);
    return { products, componentID };
}

export type ProductFilteringLoader = LoaderData<typeof productFilteringLoader>;
