import { GetResultsPerProductReportResponse } from '@apps/admin-portal-web/src/dto/filtering-simulation/runs';
import { MiniFunnelGraphDataType } from '@apps/admin-portal-web/src/components/graphs/MiniFunnelGraph';
import { SimulationGraphResponse } from '@apps/admin-portal-web/src/dto/filtering-simulation/graphs';

const FUNNEL_STEPS = {
    MATCHED: 'matched',
    APPROVED: 'approved',
    ACCEPTED: 'accepted',
    PAID_OUT: 'paidOut',
} as const;

const stepsLabel = {
    [FUNNEL_STEPS.MATCHED]: 'Match',
    [FUNNEL_STEPS.APPROVED]: 'Approved',
    [FUNNEL_STEPS.ACCEPTED]: 'Accepted',
    [FUNNEL_STEPS.PAID_OUT]: 'Paid out',
};

export type ExperimentRunPerProductData = {
    productName: string | null | undefined;
    funnel: MiniFunnelGraphDataType;
    isProductInExperiment: boolean;
};

export const parseExperimentReport =
    (experimentGraphs: SimulationGraphResponse[]) =>
    (data: GetResultsPerProductReportResponse): ExperimentRunPerProductData[] => {
        return data.map(dataItem => {
            const isProductInExperiment = !!experimentGraphs.find(
                experimentGraph => experimentGraph.productId === dataItem.productId
            );

            return {
                productName: dataItem.productName,
                funnel: [
                    {
                        id: FUNNEL_STEPS.MATCHED,
                        label: stepsLabel[FUNNEL_STEPS.MATCHED],
                        baseline: dataItem.baselineMatched,
                        simulation: dataItem.matched,
                    },
                    {
                        id: FUNNEL_STEPS.APPROVED,
                        label: stepsLabel[FUNNEL_STEPS.APPROVED],
                        baseline: dataItem.baselineApproved,
                        simulation: dataItem.approved,
                    },
                    {
                        id: FUNNEL_STEPS.ACCEPTED,
                        label: stepsLabel[FUNNEL_STEPS.ACCEPTED],
                        baseline: dataItem.baselineAccepted,
                        // For the moment we should not display values from 'Accepted' and 'Paid out' steps
                        simulation: null,
                    },
                    {
                        id: FUNNEL_STEPS.PAID_OUT,
                        label: stepsLabel[FUNNEL_STEPS.PAID_OUT],
                        baseline: dataItem.baselinePaidOut,
                        // For the moment we should not display values from 'Accepted' and 'Paid out' steps
                        simulation: null,
                    },
                ],
                percentageFromBaseline: (dataItem.approved - dataItem.baselineApproved) / dataItem.approved,
                isProductInExperiment,
            };
        });
    };
