import { OPERATORS } from '@packages/filter-graph/src/enum';
import type { Graph, FilterRule, FilterRuleWithOperators, Operator } from '@packages/filter-graph/src/types';

const operators: { [key: string]: Operator[] } = {
    integer: [
        OPERATORS.GREATER_THAN,
        OPERATORS.GREATER_EQUAL_THAN,
        OPERATORS.LOWER_THAN,
        OPERATORS.LOWER_EQUAL_THAN,
        OPERATORS.EQUAL,
        OPERATORS.NOT_EQUAL,
        OPERATORS.MISSING,
    ],
    float: [
        OPERATORS.GREATER_THAN,
        OPERATORS.GREATER_EQUAL_THAN,
        OPERATORS.LOWER_THAN,
        OPERATORS.LOWER_EQUAL_THAN,
        OPERATORS.EQUAL,
        OPERATORS.NOT_EQUAL,
        OPERATORS.MISSING,
    ],
    string: [OPERATORS.EQUAL, OPERATORS.NOT_EQUAL, OPERATORS.HAS_PREFIX, OPERATORS.HAS_NOT_PREFIX, OPERATORS.MISSING],
    bool: [OPERATORS.EQUAL, OPERATORS.NOT_EQUAL, OPERATORS.MISSING],
    enum: [OPERATORS.ONE_OF, OPERATORS.NOT_ONE_OF, OPERATORS.MISSING],
    duration: [
        OPERATORS.GREATER_THAN,
        OPERATORS.GREATER_EQUAL_THAN,
        OPERATORS.LOWER_THAN,
        OPERATORS.LOWER_EQUAL_THAN,
        OPERATORS.MISSING,
    ],
};

export function mapFilters(filters: FilterRule[]): { [key: string]: FilterRuleWithOperators } {
    return filters
        .map((filter): FilterRuleWithOperators => ({ ...filter, operators: operators[filter.valueType] || [] }))
        .reduce<{ [key: string]: FilterRuleWithOperators }>((acc, curr) => ({ ...acc, [curr.name]: curr }), {});
}

// Convert enum values to arrays from string/comma separated strings
export function graphEnumsToString(graphs: Graph[]): Graph[] {
    if (!Array.isArray(graphs)) {
        return [];
    } else {
        return graphs.map(graph => {
            for (const key in graph.nodes) {
                const node = graph.nodes[key];
                if (node && operators && operators.enum && operators.enum.includes(node.operator)) {
                    if (!Array.isArray(node.value)) {
                        node.value = node.value.includes(',') ? node.value.split(',') : [node.value];
                    }

                    // remove empty values
                    node.value = node.value.filter(i => i);
                }
            }
            return graph;
        });
    }
}
