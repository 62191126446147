import { type LoaderFunctionArgs } from 'react-router';
import {
    getFilterRules,
    getGraphs,
    getProduct,
    getProductEvents,
} from '@apps/admin-portal-web/src/actions/product-filtering';
import type {
    FilterRule,
    FilterRuleWithOperators,
    Graph,
    Product,
    ProductEvent,
} from '@packages/filter-graph/src/types';
import { mapFilters, graphEnumsToString } from '@apps/admin-portal-web/src/utils/parsers';
import { type LoaderData } from '@apps/admin-portal-web/src/types/loader';
import { getClaims } from '@apps/admin-portal-web/src/utils/cookies';

export type FilterGraphLoaderData = {
    graphs: Graph[];
    rules: { [key: string]: FilterRuleWithOperators };
    productId: string;
    workspace: string;
    componentID: string;
    product: Product;
    productEvents: ProductEvent[];
};

export async function filteringGraphLoader({ params }: LoaderFunctionArgs): Promise<FilterGraphLoaderData> {
    const auth = getClaims();
    if (!auth) {
        throw new Error('no auth claims found');
    }

    const workspace = params.workspace as string;
    const componentID = params.componentID as string;
    const productId = params.productID as string;
    const [filters, graphs, product, productEvents]: [FilterRule[], Graph[], Product, ProductEvent[]] =
        await Promise.all([
            getFilterRules(componentID),
            getGraphs(productId, componentID),
            getProduct(productId, componentID),
            getProductEvents(productId, componentID),
        ]);

    const rules = mapFilters(filters);
    const parsedGraphs = graphEnumsToString(graphs);
    const reversedProductEvents = productEvents.reverse();

    return {
        graphs: parsedGraphs,
        rules,
        productId,
        workspace,
        componentID,
        product,
        productEvents: reversedProductEvents,
    };
}

export type FilteringGraphLoader = LoaderData<typeof filteringGraphLoader>;
