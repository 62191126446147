import { type WorkspaceType, type WorkspaceAvailabilityType } from '@apps/admin-portal-web/src/types/workspaces';
import { transport } from './transport';
import { pathEscape } from '../utils/pathEscape';

export function getWorkspaces() {
    return transport.get<WorkspaceType[]>('/api/workspaces');
}

export function checkWorkspaceName(name: string) {
    return transport.get<WorkspaceAvailabilityType>(`/api/workspaces/${pathEscape(name)}/availability`);
}

export function createWorkspace(data: WorkspaceType) {
    return transport.post<WorkspaceType, WorkspaceType>(`/api/workspaces`, data);
}

export function getWorkspaceByName(name: string) {
    return transport.get<WorkspaceType>(`/api/workspaces/${pathEscape(name)}`);
}

export function assignUserToWorkspace(workspace: string, userID: string) {
    return transport.put<void, void>(`/api/workspaces/${pathEscape(workspace)}/users/${pathEscape(userID)}`);
}

export function unAssignUserToWorkspace(workspace: string, userID: string) {
    return transport.delete<void>(`/api/workspaces/${pathEscape(workspace)}/users/${pathEscape(userID)}`);
}
