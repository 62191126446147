import { type LoaderFunctionArgs } from 'react-router';
import { getExperiments, getSimulationRuns } from '@apps/admin-portal-web/src/actions/simulation';
import { type LoaderData } from '@apps/admin-portal-web/src/types/loader';
import { getClaims } from '@apps/admin-portal-web/src/utils/cookies';

export async function simulationRunsLoader({ params }: LoaderFunctionArgs) {
    const auth = getClaims();
    if (!auth) {
        throw new Error('no auth claims found');
    }

    const workspace = params.workspace as string;
    const componentId = params.componentID as string;

    const runs = await getSimulationRuns(componentId, workspace);
    const experiments = await getExperiments(componentId, workspace);

    return { componentId, workspace, runs, experiments };
}

export type SimulationRunsLoader = LoaderData<typeof simulationRunsLoader>;
