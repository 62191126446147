import { type UserPermissionsType, type UserType } from '@apps/admin-portal-web/src/types/user';
import { camelToSnakeSearch } from '@apps/admin-portal-web/src/utils/searchParams';
import { transport } from './transport';
import { pathEscape } from '../utils/pathEscape';

export function getUsers(workspace: string, email?: string, id?: string) {
    return transport.get<UserType[]>(`/api/users?${camelToSnakeSearch({ email, id }, { removeEmpty: true })}`, {
        workspace,
    });
}

export function getUserPermissions() {
    return transport.get<UserPermissionsType>(`/api/auth/privileges`);
}

/**
 * Create a user in the system,
 * this step is required before to assign workspace to the user
 */
export function createUser(workspace: string, email: string) {
    interface CreateUserType {
        email: string;
    }
    return transport.post<CreateUserType, UserType>(`/api/users`, { email }, { workspace });
}

export function getWorkspaceUsers(workspace: string, id?: string) {
    return transport.get<UserType[]>(
        `/api/workspaces/${pathEscape(workspace)}/users?${camelToSnakeSearch({ id }, { removeEmpty: true })}`,
        { workspace }
    );
}
