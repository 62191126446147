import { type LoaderFunctionArgs, redirect } from 'react-router';
import { getUserPermissions } from '@apps/admin-portal-web/src/actions/users';
import { type LoaderData } from '@apps/admin-portal-web/src/types/loader';
import { getClaims } from '@apps/admin-portal-web/src/utils/cookies';
import { redirectUrlStore } from '@apps/admin-portal-web/src/stores/redirectUrl';
import { redirectUrlFromString } from '@apps/admin-portal-web/src/utils/redirectUrl';

export async function rootLoader({ request, params }: LoaderFunctionArgs) {
    const auth = getClaims();
    if (!auth) {
        const redirectUrl = redirectUrlFromString(request.url);
        if (redirectUrl) {
            const { setRedirectUrl } = redirectUrlStore();
            setRedirectUrl(redirectUrl);
        }

        return redirect('/login');
    }

    const userPermissions = await getUserPermissions();

    return {
        userPermissions,
        workspace: params.workspace,
    };
}

export type RootLoader = LoaderData<typeof rootLoader>;
