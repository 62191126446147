import { type RouteObject } from 'react-router';
import workspaceRoutes from '@apps/admin-portal-web/src/pages/workspace/workspaceRoutes';
import homeRoutes from '@apps/admin-portal-web/src/pages/home/homeRoutes';
import { ErrorBoundary } from '@apps/admin-portal-web/src/pages/ErrorBoundary';
import { rootLoader } from '@apps/admin-portal-web/src/pages/root/rootLoader';
import { loginLoader } from '@apps/admin-portal-web/src/pages/login/loginLoader';
import { AuthenticatedLayout } from './layouts/authenticated';
import { UnauthenticatedLayout } from './layouts/unauthenticated';

const rootRoute: RouteObject = {
    path: '/',
    loader: rootLoader,
    lazy: () => import('@apps/admin-portal-web/src/pages/root'),
    ErrorBoundary,
    children: [...homeRoutes, ...workspaceRoutes],
};

const authenticatedRoute: RouteObject = {
    Component: AuthenticatedLayout,
    children: [rootRoute],
};

const loginRoute: RouteObject = {
    path: '/login',
    loader: loginLoader,
    lazy: () => import('@apps/admin-portal-web/src/pages/login'),
    ErrorBoundary,
};

const unauthenticatedRoute: RouteObject = {
    Component: UnauthenticatedLayout,
    children: [loginRoute],
};

const pageNotFound: RouteObject = {
    path: '*',
    lazy: () => import('@apps/admin-portal-web/src/pages/system/notFound'),
};

export default [authenticatedRoute, unauthenticatedRoute, pageNotFound];
