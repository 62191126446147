import { type LoaderFunctionArgs } from 'react-router-dom';
import { type ComponentWithProps, type SectionValueType } from '@apps/admin-portal-web/src/types/sidebar';
import { getWorkspaceComponents } from '@apps/admin-portal-web/src/actions/components';
import { type ApiErrorInterface } from '@apps/admin-portal-web/src/types/error';
import { type LoaderData } from '@apps/admin-portal-web/src/types/loader';
import { type WorkspaceComponentInstance } from '@apps/admin-portal-web/src/types/components';
import { getClaims } from '@apps/admin-portal-web/src/utils/cookies';
import { getWorkspaceByName } from '@apps/admin-portal-web/src/actions/workspaces';
import { parseWorkspaceToForm } from './dashboard/utils';
import { WorkspaceType } from '../../types/workspaces';

export async function workspaceRoutesLoader({ params }: LoaderFunctionArgs) {
    const auth = getClaims();
    if (!auth) {
        throw new Error('no auth claims found');
    }

    const workspaceName = params.workspace as string;

    let workspace: WorkspaceType | undefined;
    let isWorkspaceValid = true;
    try {
        workspace = await getWorkspaceByName(workspaceName);
    } catch (e: any) {
        if (e.type === 'ApiError') {
            const apiError = e as ApiErrorInterface;
            isWorkspaceValid = apiError.response.ok;
        }
    }

    let components: WorkspaceComponentInstance[] = [];
    let componentsByType: { [key in SectionValueType]?: ComponentWithProps[] } = {};
    let componentInstance: WorkspaceComponentInstance | undefined;

    if (isWorkspaceValid) {
        const componentsFromApi = await getWorkspaceComponents(workspaceName);

        components = componentsFromApi.map(parseWorkspaceToForm);

        componentInstance = components.find(ci => ci.id === params.componentID);

        componentsByType = components.reduce(
            (acc: { [key in SectionValueType]?: ComponentWithProps[] }, curr: WorkspaceComponentInstance) => {
                acc[curr.component] = acc[curr.component] || [];

                acc[curr.component]?.push({
                    ...curr,
                    ...{ pathname: `${curr.component}/${curr.id}`.replace('_', '-') },
                });

                return acc;
            },
            {}
        );
    }

    return { isWorkspaceValid, components, componentsByType, workspace: workspace as WorkspaceType, componentInstance };
}

export type WorkspaceRoutesLoader = LoaderData<typeof workspaceRoutesLoader>;
