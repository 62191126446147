import { type LoaderFunctionArgs } from 'react-router';
import { getExperiments } from '@apps/admin-portal-web/src/actions/simulation';
import { type LoaderData } from '@apps/admin-portal-web/src/types/loader';
import { getClaims } from '@apps/admin-portal-web/src/utils/cookies';

export async function simulationExperimentsLoader({ params }: LoaderFunctionArgs) {
    const auth = getClaims();
    if (!auth) {
        throw new Error('no auth claims found');
    }

    const workspace = params.workspace as string;
    const componentId = params.componentID as string;
    const experiments = await getExperiments(componentId, workspace);

    return {
        componentId,
        experiments,
        workspace,
    };
}

export type SimulationExperimentsLoader = LoaderData<typeof simulationExperimentsLoader>;
