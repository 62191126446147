import { transport } from './transport';
import { type AuthTokenClaims } from '../types/auth';

interface authenticateUsingOktaCodeRequest {
    code: string;
    redirect_url: string;
}

export function authenticateUsingOktaCode(data: authenticateUsingOktaCodeRequest) {
    return transport.post<authenticateUsingOktaCodeRequest, void>('/api/authentication/okta', data);
}

export function logout() {
    return transport.delete<void>('/api/auth/session').then(() => {
        sessionStorage && sessionStorage.clear();
    });
}

export function renewSession() {
    return transport.post<void, AuthTokenClaims>('/api/auth/session/renew', undefined);
}
