import { env } from '@apps/admin-portal-web/src/configs/env';
import { RouterProvider } from 'react-router-dom';
import Tracking from '@apps/admin-portal-web/src/utils/tracking';
import routes from './Routes';
import { sentryCreateBrowserRouter } from './configs/sentry';
import './configs/dayjs';

if (env.MODE === 'msw') {
    const { worker } = await import('../mocks/browser');
    console.info('%c Running with mock server', 'background-color:#f59e0a;color:white;');
    worker.start({ quiet: true });
}

if (env.GA4_MEASUREMENT_ID) {
    Tracking.initialize(env.GA4_MEASUREMENT_ID);
}

export const router = sentryCreateBrowserRouter(routes);

export function App() {
    return <RouterProvider router={router} />;
}
