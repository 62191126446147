import { type LoaderFunctionArgs } from 'react-router';
import {
    getFilterRuleProducts,
    getFilteringRequests,
    getFilterRules,
    getGraphs,
    getProduct,
} from '@apps/admin-portal-web/src/actions/product-filtering';
import type { FilterRequestType } from '@apps/admin-portal-web/src/types/filtering-request';
import type { FilterRule, Graph, Product, Request } from '@packages/filter-graph/src/types';
import { mapFilters, graphEnumsToString } from '@apps/admin-portal-web/src/utils/parsers';
import { valuesFromSearchParams } from '@apps/admin-portal-web/src/utils/searchParams';
import { getClaims } from '@apps/admin-portal-web/src/utils/cookies';
import { LoaderData } from '@apps/admin-portal-web/src/types/loader';
import { FilterResultType, GraphNodeEventType, ResultStatus } from '@apps/admin-portal-web/src/types/filtering-results';

export type ApplicantData = {
    id: string;
    applicationId: string;
    applicationRevision: number;
    results: FilterResultType[];
    createdAt: string;
    request: Request;
};

const parseApplicantData = ({ request, ...rest }: FilterRequestType): ApplicantData => {
    let applicants = {};
    for (const applicant in request.applicants) {
        if (applicant && request.applicants[applicant] !== null) {
            applicants = { ...applicants, [applicant]: request.applicants[applicant] };
        }
    }
    request.applicants = applicants;
    request.properties = request.properties || {};
    return { request, ...rest };
};

interface GetResultGraphProps {
    applicationId?: string;
    applicationRevision?: string;
    graphId?: string;
    productId: string;
    componentId: string;
    displayResultGraph: boolean;
    requestId?: string;
}

async function getResultGraph({
    applicationId,
    applicationRevision,
    graphId,
    productId,
    componentId,
    displayResultGraph,
    requestId,
}: GetResultGraphProps) {
    const [filters, graphs, product, filteringRequests]: [FilterRule[], Graph[], Product, FilterRequestType[]] =
        await Promise.all([
            getFilterRules(componentId),
            getGraphs(productId, componentId),
            getProduct(productId, componentId),
            getFilteringRequests(componentId, applicationId, requestId),
        ]);

    const rules = mapFilters(filters);
    const parsedGraphs = graphEnumsToString(graphs);
    const request = filteringRequests
        .map(req => parseApplicantData(req))
        .find(a => a.applicationRevision === Number(applicationRevision))?.request;
    const graph = parsedGraphs.find(graph => graph.id === graphId); // TODO: We should ensure that we only fetch a single graph here!

    if (!graph) {
        throw new Error('Graph Id not found');
    }

    if (!filteringRequests[0]) {
        throw new Error('Filter request are not defined');
    }

    const result = filteringRequests[0].results.find(result => result.graphId === graphId); // TODO: We should ensure we fetch only the results for the graph being fetched.

    result?.graphNodeEvents.forEach(
        ({ graphNode, ...event }) => {
            const node = graph.nodes[graphNode.id];
            if (node && node.event) {
                node.event = event;
                return node;
            }
            return undefined;
        } // TODO: Look into if we can get this prefilled with the graph from the backend instead.
    );

    return {
        graphResult: result?.result,
        displayResultGraph,
        rules,
        graph,
        product,
        request,
    };
}

interface GetResultListProps {
    componentId: string;
    applicationId?: string;
    displayResultGraph: boolean;
    requestId?: string;
}

export type ResultList = {
    displayResultGraph: boolean;
    results: FilteringRequestsData[];
};

export type FilteringRequestsData = {
    applicationId?: string;
    applicationRevision?: number;
    createdAt: string;
    request: {
        applicants?: {
            [key: string]: {
                [key: string]: string;
            };
        } | null;
        properties?: {
            [key: string]: string;
        } | null;
    };
    results: FilteringRequestResult[];
};

export type FilteringRequestResult = {
    graphId: string;
    productId: string;
    productName: string;
    graphNodeEvents: GraphNodeEventType[];
    result: ResultStatus;
};

async function getResultList({
    componentId,
    applicationId,
    displayResultGraph,
    requestId,
}: GetResultListProps): Promise<ResultList> {
    const filteringRequests = await getFilteringRequests(componentId, applicationId, requestId);
    const products = await getFilterRuleProducts(componentId);
    const retData: FilteringRequestsData[] = [];

    // TODO: The aggregation of data (adding the product name) should probably be done in the backend
    // and passed to the frontend instead.
    for (let i = 0; i < filteringRequests.length; i++) {
        const filteringRequest = filteringRequests[i];
        const results: FilteringRequestResult[] = [];
        if (!filteringRequest) {
            continue;
        }
        for (let j = 0; j < filteringRequest.results.length; j++) {
            const result = filteringRequest.results[j];

            if (!result) {
                continue;
            }

            const product: Product | undefined = products.find(product => product.id === result.productId);
            let productName = 'unknown'; // TODO: Could probably improve this a bit
            if (product !== undefined) {
                productName = product.name;
            }
            results.push({
                graphId: result.graphId,
                productId: result.productId,
                productName: productName,
                graphNodeEvents: result.graphNodeEvents,
                result: result.result,
            });
        }
        retData.push({
            applicationId: filteringRequest.applicationId,
            applicationRevision: filteringRequest.applicationRevision,
            createdAt: filteringRequest.createdAt,
            request: filteringRequest.request,
            results: results,
        });
    }

    return { results: retData, displayResultGraph };
}

// TODO: We should not return conditional data from loaders. There should
// instead be two different loaders for two different pages.
export async function filteringResultsLoader(props: LoaderFunctionArgs) {
    const auth = getClaims();
    if (!auth) {
        throw new Error('no auth claims found');
    }

    const componentId = props.params.componentID as string;
    const url = new URL(props.request.url);
    const { applicationId, applicationRevision, graphId, productId, requestId } = valuesFromSearchParams(
        url.searchParams
    );
    const displayResultGraph = Boolean(graphId);

    if (displayResultGraph) {
        if (!productId) {
            throw new Error('Product id not defined');
        }
        return await getResultGraph({
            applicationId,
            applicationRevision,
            graphId,
            productId,
            componentId,
            displayResultGraph,
            requestId,
        });
    } else {
        return await getResultList({ componentId, applicationId, displayResultGraph, requestId });
    }
}

export type ResultListLoader = Awaited<ReturnType<typeof getResultList>>;
export type ResultGraphLoader = Awaited<ReturnType<typeof getResultGraph>>;
export type FilteringResultsLoader = LoaderData<typeof filteringResultsLoader>;
