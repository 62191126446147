import { ConsentOpts, EventName, EventPayload } from '@apps/admin-portal-web/src/types/tracking';
import { camelToSnake } from './camelToSnake';

class Tracking {
    initialized: boolean;
    measurementId: string;

    constructor() {
        this.initialized = false;
        this.measurementId = '';
    }

    initialize = (measurementId: string) => {
        if (!measurementId) return;
        this.measurementId = measurementId;

        if (this.initialized) return;
        this.initialized = true;

        loadGTagScript(measurementId);

        this.set('js', new Date());
        this.consent('default', {
            ad_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            analytics_storage: 'granted',
        });
        this.config({ send_page_view: false });
    };

    set = (key: 'js', value: any) => {
        this.gtag(key, value);
    };

    consent = (mode: 'default' | 'update', opts: ConsentOpts) => {
        this.gtag('consent', mode, opts);
    };

    config = (opts: {}) => {
        this.gtag('config', this.measurementId, opts);
    };

    send = <T extends EventName>(eventName: T, eventProperties: EventPayload<T>) => {
        const params: [string, any?] = [eventName];

        if (eventProperties !== undefined) {
            // Convert keys to "lower_snake_case".
            const properties = camelToSnake(eventProperties);

            // Remove all undefined properties.
            // @ts-ignore
            Object.keys(properties).forEach(key => properties[key] === undefined && delete properties[key]);

            // Only send event properties parameter if there's at least one property.
            if (Object.keys(properties).length > 0) {
                params.push(properties);
            }
        }

        this.gtag('event', ...params);
    };

    gtag = (...args: any[]): void => {
        if (!this.initialized) return;
        if (typeof window.gtag === 'undefined') {
            window.dataLayer.push(args);
        } else {
            window.gtag(...args);
        }
    };
}

export default new Tracking();

declare global {
    interface Window {
        dataLayer: any[];
        gtag: (...args: any[]) => void | undefined;
    }
}

function loadGTagScript(measurementId: string) {
    if (typeof window === 'undefined' || typeof document === 'undefined') {
        return [];
    }
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    document.body.appendChild(script);
    window.dataLayer = window.dataLayer || [];
    if (typeof window.gtag === 'undefined') {
        window.gtag = function gtag() {
            // eslint-disable-next-line prefer-rest-params
            window.dataLayer.push(arguments);
        };
    }
}
