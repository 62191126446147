import { GetFunnelRateReportResponse } from '@apps/admin-portal-web/src/dto/filtering-simulation/runs';
import { FunnelData } from '../cards/FunnelCard';

const FUNNEL_STEPS = {
    MATCHES: 'nrOfMatches',
    APPROVALS: 'nrOfApprovals',
    ACCEPTANCES: 'nrOfAcceptances',
    PAID_OUTS: 'nrOfPaidOuts',
} as const;

const stepsLabel = {
    [FUNNEL_STEPS.MATCHES]: 'Match',
    [FUNNEL_STEPS.APPROVALS]: 'Approved',
    [FUNNEL_STEPS.ACCEPTANCES]: 'Accepted',
    [FUNNEL_STEPS.PAID_OUTS]: 'Paid out',
};

const FUNNEL_STEPS_ORDER = [
    FUNNEL_STEPS.MATCHES,
    FUNNEL_STEPS.APPROVALS,
    FUNNEL_STEPS.ACCEPTANCES,
    FUNNEL_STEPS.PAID_OUTS,
] as const;

export const parseFunnelResponse = (data: GetFunnelRateReportResponse): FunnelData => {
    return {
        nrOfApplicaitons: undefined,
        applicantData: FUNNEL_STEPS_ORDER.map(step => {
            const skipFunnelStepValues: (typeof FUNNEL_STEPS)[keyof typeof FUNNEL_STEPS][] = [
                FUNNEL_STEPS.ACCEPTANCES,
                FUNNEL_STEPS.PAID_OUTS,
            ];

            return {
                id: step,
                label: stepsLabel[step],
                baseline: data.baseline[step],
                // For the moment we should not display values from 'Accepted' and 'Paid out' steps
                simulation: skipFunnelStepValues.includes(step) ? null : data.simulation[step],
            };
        }),
    };
};
