import { Card, CardHeader } from '@packages/shadcn/ui/card';
import { useClaims } from '@apps/admin-portal-web/src/hooks/useClaims';

type HomeComponentPropType = {
    email: string;
};

export function HomeComponent({ email }: HomeComponentPropType) {
    return (
        <div className="px-2 py-6">
            <Card>
                <CardHeader>
                    <p className="container font-sans font-extralight">
                        Welcome <span className="font-bold">{email}</span> to your back office.
                    </p>
                </CardHeader>
            </Card>
        </div>
    );
}

export function Home() {
    const claims = useClaims();

    return <HomeComponent email={claims?.user?.email} />;
}
