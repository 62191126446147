import { createSearchParams } from 'react-router-dom';
import { camelToSnake } from './camelToSnake';
import { snakeToCamel } from './snakeToCamel';

export const valuesFromSearchParams = (searchParams: URLSearchParams): Record<string, string> =>
    snakeToCamel(
        [...searchParams.entries()].reduce(
            (acc, [key, value]) => ({
                ...acc,
                [key]: value,
            }),
            {}
        )
    );

type camelToSnakeSearchType = (params: { [key: string]: any }, options?: { removeEmpty: boolean }) => string;

const defaultOptions = {
    removeEmpty: false,
};

export const camelToSnakeSearch: camelToSnakeSearchType = (params, options = defaultOptions) => {
    let filteredParams: { [key: string]: any } = {};

    if (options.removeEmpty) {
        filteredParams = removeEmptyValues(params);
    }

    const objectToParse = options.removeEmpty ? filteredParams : params;

    return createSearchParams(camelToSnake(objectToParse)).toString();
};

export const removeEmptyValues = (params: { [key: string]: any }) => {
    const output: any = {};

    for (const key in params) {
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty(key) && params[key]) {
            output[key] = params[key];
        }
    }
    return output;
};
