import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { type AuthTokenClaims } from '@apps/admin-portal-web/src/types/auth';
import { getClaims } from '@apps/admin-portal-web/src/utils/cookies';

export function useClaims(): AuthTokenClaims {
    const claims = getClaims();

    useEffect(() => {
        if (claims) {
            Sentry.setUser({ email: claims.user.email });
        } else {
            Sentry.setUser(null);
        }
    }, [claims]);

    if (!claims) {
        throw new Error('no auth claims found');
    }

    return claims;
}
