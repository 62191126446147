import { type LoaderFunctionArgs, redirect } from 'react-router';
import { type LoaderData } from '@apps/admin-portal-web/src/types/loader';
import { authenticateUsingOktaCode } from '../../actions/auth';
import { oktaRedirectUri } from '../../actions/okta';
import { redirectUrlStore } from '../../stores/redirectUrl';

export async function loginLoader({ request }: LoaderFunctionArgs) {
    const url = new URL(request.url);
    const { getRedirectUrl, clearRedirectUrl } = redirectUrlStore();

    const code = url.searchParams.get('code');
    if (code) {
        await authenticateUsingOktaCode({ code, redirect_url: oktaRedirectUri });

        const redirectUrl = getRedirectUrl();
        if (redirectUrl) {
            clearRedirectUrl();
            return redirect(redirectUrl);
        }

        return redirect('/');
    }

    return null;
}

export type LoginLoader = LoaderData<typeof loginLoader>;
