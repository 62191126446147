import { type LoaderFunctionArgs } from 'react-router';
import {
    getExperimentGraphs,
    getExperimentById,
    getPartnerProducts,
} from '@apps/admin-portal-web/src/actions/simulation';

import { type LoaderData } from '@apps/admin-portal-web/src/types/loader';
import { getClaims } from '@apps/admin-portal-web/src/utils/cookies';

export async function simulationExperimentsDetailLoader({ params }: LoaderFunctionArgs) {
    const auth = getClaims();
    if (!auth) {
        throw new Error('no auth claims found');
    }

    const workspace = params.workspace as string;
    const componentId = params.componentID as string;
    const experimentId = params.experimentId as string;

    const [graphs, products, experiment] = await Promise.all([
        getExperimentGraphs(experimentId, componentId, workspace),
        getPartnerProducts(componentId, workspace),
        getExperimentById(experimentId, componentId, workspace),
    ]);

    return { workspace, componentId, experimentId, experiment, graphs, products };
}

export type SimulationExperimentsDetailLoader = LoaderData<typeof simulationExperimentsDetailLoader>;
