import { COMPONENT_TYPES, WorkspaceComponentInstance } from './components';

export const SECTIONS = {
    DASHBOARD: 'dashboard',
    USERS: 'workspace-users',
    ...COMPONENT_TYPES,
} as const;

export type SectionValueType = (typeof SECTIONS)[keyof typeof SECTIONS];

export type ComponentValueType = (typeof COMPONENT_TYPES)[keyof typeof COMPONENT_TYPES];

export interface ComponentWithProps extends WorkspaceComponentInstance {
    pathname: string;
}
