export type FAQItem = {
    id: string;
    q: string;
    a: string;
    pages: FAQ_PAGE[];
    weight?: number;
};

export const FAQ_PAGES = {
    ALL: '*',

    DASHBOARD: 'dashboard',

    WORKSPACE_USERS: 'workspace-users',
    WORKSPACE_USER: 'workspace-user',

    PARTNER_PRODUCTS: 'partner-products',

    PRODUCT_FILTERING_GRAPH: 'product-filtering-graph',
    PRODUCT_FILTERING_PRODUCTS: 'product-filtering-products',
    PRODUCT_FILTERING_RESULTS: 'product-filtering-results',

    FILTERING_SIMULATION_EXPERIMENT_GRAPH: 'filtering-simulation-experiment-graph',
    FILTERING_SIMULATION_EXPERIMENT: 'filtering-simulation-experiment',
    FILTERING_SIMULATION_EXPERIMENTS: 'filtering-simulation-experiments',
    FILTERING_SIMULATION_RESULTS: 'filtering-simulation-results',
    FILTERING_SIMULATION_RUNS: 'filtering-simulation-runs',
} as const;

export type FAQ_PAGE = (typeof FAQ_PAGES)[keyof typeof FAQ_PAGES];
