import { type LoaderFunctionArgs } from 'react-router';
import { getRoles, getUserRoles } from '@apps/admin-portal-web/src/actions/authorization';
import { getUsers } from '@apps/admin-portal-web/src/actions/users';
import { type LoaderData } from '@apps/admin-portal-web/src/types/loader';
import { getClaims } from '@apps/admin-portal-web/src/utils/cookies';

export async function editWorkspaceUsersLoader({ params }: LoaderFunctionArgs) {
    const auth = getClaims();
    if (!auth) {
        throw new Error('no auth claims found');
    }

    const workspace = params.workspace as string;
    const userId = params.userId as string;
    const users = await getUsers(workspace, undefined, userId);

    if (!users?.length) {
        throw new Error(`User "${userId}" was not found`);
    }

    const currentUser = users[0];

    const roles = await getRoles(workspace);
    const userRoles = await getUserRoles(userId, workspace);

    return {
        roles,
        user: currentUser,
        workspace,
        userRoles,
    };
}

export type EditWorkspaceUsersLoader = LoaderData<typeof editWorkspaceUsersLoader>;
