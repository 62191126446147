export type SnakeCaseKeys<T extends object | undefined | null> = T extends undefined
    ? undefined
    : T extends null
    ? null
    : T extends Array<infer ArrayType>
    ? ArrayType extends object
        ? Array<SnakeCaseKeys<ArrayType>>
        : Array<ArrayType>
    : T extends Uint8Array
    ? Uint8Array
    : T extends Date
    ? Date
    : {
          [K in keyof T as SnakeCase<K>]: T[K] extends Array<infer ArrayType> | undefined | null
              ? ArrayType extends object
                  ? Array<SnakeCaseKeys<ArrayType>>
                  : Array<ArrayType>
              : T[K] extends object | undefined | null
              ? SnakeCaseKeys<T[K]>
              : T[K];
      };
export type SnakeCase<S extends string | number | symbol> = S extends string
    ? S extends `${infer Head}${CapitalChars}${infer Tail}` // string has a capital char somewhere
        ? Head extends '' // there is a capital char in the first position
            ? Tail extends ''
                ? Lowercase<S> /*  'A' */
                : S extends `${infer Caps}${Tail}` // tail exists, has capital characters
                ? Caps extends CapitalChars
                    ? Tail extends CapitalLetters
                        ? `${Lowercase<Caps>}_${Lowercase<Tail>}` /* 'AB' */
                        : Tail extends `${CapitalLetters}${string}`
                        ? `${SnakeCase<Caps>}_${SnakeCase<Tail>}` /* first tail char is upper? 'ABcd' */
                        : `${SnakeCase<Caps>}${SnakeCase<Tail>}` /* 'AbCD','AbcD',  */ /* TODO: if tail is only numbers, append without underscore */
                    : never /* never reached, used for inference of caps */
                : never
            : Tail extends '' /* 'aB' 'abCD' 'ABCD' 'AB' */
            ? S extends `${Head}${infer Caps}`
                ? Caps extends CapitalChars
                    ? Head extends Lowercase<Head> /* 'abcD' */
                        ? Caps extends Numbers
                            ? // Head exists and is lowercase, tail does not, Caps is a number, we may be in a sub-select
                              // if head ends with number, don't split head an Caps, keep contiguous numbers together
                              Head extends `${string}${Numbers}`
                                ? never
                                : // head does not end in number, safe to split. 'abc2' -> 'abc_2'
                                  `${SnakeCase<Head>}_${Caps}`
                            : `${SnakeCase<Head>}_${SnakeCase<Caps>}` /* 'abcD' 'abc25' */
                        : never /* stop union type forming */
                    : never
                : never /* never reached, used for inference of caps */
            : S extends `${Head}${infer Caps}${Tail}` /* 'abCd' 'ABCD' 'AbCd' 'ABcD' */
            ? Caps extends CapitalChars
                ? Head extends Lowercase<Head> /* is 'abCd' 'abCD' ? */
                    ? Tail extends CapitalLetters /* is 'abCD' where Caps = 'C' */
                        ? `${SnakeCase<Head>}_${SnakeCase<Caps>}_${Lowercase<Tail>}` /* aBCD Tail = 'D', Head = 'aB' */
                        : Tail extends `${CapitalLetters}${string}` /* is 'aBCd' where Caps = 'B' */
                        ? Head extends Numbers
                            ? never /* stop union type forming */
                            : Head extends `${string}${Numbers}`
                            ? never /* stop union type forming */
                            : `${Head}_${SnakeCase<Caps>}_${SnakeCase<Tail>}` /* 'aBCd' => `${'a'}_${Lowercase<'B'>}_${ToSnake<'Cd'>}` */
                        : `${SnakeCase<Head>}_${Lowercase<Caps>}${SnakeCase<Tail>}` /* 'aBcD' where Caps = 'B' tail starts as lowercase */
                    : never
                : never
            : never
        : S /* 'abc'  */
    : never;
type CapitalLetters =
    | 'A'
    | 'B'
    | 'C'
    | 'D'
    | 'E'
    | 'F'
    | 'G'
    | 'H'
    | 'I'
    | 'J'
    | 'K'
    | 'L'
    | 'M'
    | 'N'
    | 'O'
    | 'P'
    | 'Q'
    | 'R'
    | 'S'
    | 'T'
    | 'U'
    | 'V'
    | 'W'
    | 'X'
    | 'Y'
    | 'Z';

type Numbers = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';

type CapitalChars = CapitalLetters | Numbers;

export function camelToSnake<T extends object | null | undefined>(obj: T): SnakeCaseKeys<T>;
export function camelToSnake<T extends object | null | undefined>(obj: T[]): SnakeCaseKeys<T>[];
export function camelToSnake(obj: unknown): unknown {
    if (typeof obj !== 'object' || obj === null || obj === undefined) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(camelToSnake);
    }

    const result = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            let snakeKey = key.charAt(0).toLowerCase() + key.slice(1); // Prevent prefixing with `_` when the first character is uppercase.
            snakeKey = snakeKey.replace(/[A-Z]/g, match => '_' + match.toLowerCase());
            //@ts-ignore
            result[snakeKey] = camelToSnake(obj[key]);
        }
    }

    return result;
}
