import type {
    CloneExperimentRequest,
    CloneExperimentResponse,
    CreateExperimentRequest,
    SimulationExperimentResponse,
    UpdateExperimentRequest,
} from '@apps/admin-portal-web/src/dto/filtering-simulation/experiments';
import type {
    CreateExperimentFilterRuleRequest,
    SimulationExperimentFilterRuleResponse,
    UpdateExperimentFilterRuleRequest,
} from '@apps/admin-portal-web/src/dto/filtering-simulation/filter-rules';
import type {
    CreateExperimentRunRequest,
    GetSimulationPartnerProductType,
    SimulationRunResponse,
    GetSimulationRunsResponse,
    SimulationExperimentRunResponse,
    GetApplicationsReportResponse,
    GetResultsPerProductReportResponse,
    GetSegmentsReportResponse,
    GetUniqueVolumeChangeReportResponse,
    GetFunnelRateReportResponse,
    GetExperimentRunProgressReportResponse,
} from '@apps/admin-portal-web/src/dto/filtering-simulation/runs';
import type {
    CreateExperimentGraphRequest,
    ImportGraphsToExperimentRequest,
    UpdateExperimentGraphRequest,
    SimulationGraphResponse,
    ImportGraphsToExperimentResponse,
} from '@apps/admin-portal-web/src/dto/filtering-simulation/graphs';
import { pathEscape } from '../utils/pathEscape';
import { transport } from './transport';

export function getExperiments(componentId: string, workspace: string) {
    return transport.get<SimulationExperimentResponse[]>(`/api/filtering-simulation/experiments`, {
        componentId,
        workspace,
    });
}

export function getExperimentById(experimentId: string, componentId: string, workspace: string) {
    return transport.get<SimulationExperimentResponse>(
        `/api/filtering-simulation/experiments/${pathEscape(experimentId)}`,
        {
            componentId,
            workspace,
        }
    );
}

export function createExperiment(experiment: CreateExperimentRequest, componentId: string, workspace: string) {
    return transport.post<CreateExperimentRequest, SimulationExperimentResponse>(
        `/api/filtering-simulation/experiments`,
        experiment,
        {
            componentId,
            workspace,
        }
    );
}

export function updateExperiment(
    experiment: UpdateExperimentRequest,
    experimentId: string,
    componentId: string,
    workspace: string
) {
    return transport.patch<UpdateExperimentRequest, SimulationExperimentResponse>(
        `/api/filtering-simulation/experiments/${pathEscape(experimentId)}`,
        experiment,
        {
            componentId,
            workspace,
        }
    );
}

export function getExperimentFilterRules(experimentId: string, componentId: string, workspace: string) {
    return transport.get<SimulationExperimentFilterRuleResponse[]>(
        `/api/filtering-simulation/experiments/${pathEscape(experimentId)}/filter-rules`,
        {
            componentId,
            workspace,
        }
    );
}

export function createExperimentFilterRule(
    rule: CreateExperimentFilterRuleRequest,
    experimentId: string,
    componentId: string,
    workspace: string
) {
    return transport.post<CreateExperimentFilterRuleRequest, SimulationExperimentFilterRuleResponse>(
        `/api/filtering-simulation/experiments/${pathEscape(experimentId)}/filter-rules`,
        rule,
        {
            componentId,
            workspace,
        }
    );
}

export function updateExperimentFilterRule(
    rule: UpdateExperimentFilterRuleRequest,
    ruleId: string,
    experimentId: string,
    componentId: string,
    workspace: string
) {
    return transport.patch<UpdateExperimentFilterRuleRequest, SimulationExperimentFilterRuleResponse>(
        `/api/filtering-simulation/experiments/${pathEscape(experimentId)}/filter-rules/${pathEscape(ruleId)}`,
        rule,
        {
            componentId,
            workspace,
        }
    );
}

export function getExperimentGraphs(experimentId: string, componentId: string, workspace: string) {
    return transport.get<SimulationGraphResponse[]>(
        `/api/filtering-simulation/experiments/${pathEscape(experimentId)}/graphs`,
        {
            componentId,
            workspace,
        }
    );
}

export function getExperimentGraphById(experimentId: string, graphId: string, componentId: string, workspace: string) {
    return transport.get<SimulationGraphResponse>(
        `/api/filtering-simulation/experiments/${pathEscape(experimentId)}/graphs/${pathEscape(graphId)}`,
        {
            componentId,
            workspace,
        }
    );
}

export function importGraphsToExperiment(
    experimentId: string,
    includeAllGraphs: boolean,
    productIds: string[],
    componentId: string,
    workspace: string
) {
    return transport.post<ImportGraphsToExperimentRequest, ImportGraphsToExperimentResponse>(
        `/api/filtering-simulation/experiments/${pathEscape(experimentId)}/import-graphs`,
        {
            all: includeAllGraphs,
            productIds,
        },
        {
            workspace,
            componentId,
        }
    );
}

export function createExperimentGraph(
    data: CreateExperimentGraphRequest,
    experimentId: string,
    componentId: string,
    workspace: string
) {
    return transport.post<CreateExperimentGraphRequest, SimulationGraphResponse>(
        `/api/filtering-simulation/experiments/${pathEscape(experimentId)}/graphs`,
        data,
        {
            componentId,
            workspace,
        }
    );
}

export function updateExperimentGraph(
    data: UpdateExperimentGraphRequest,
    graphId: string,
    experimentId: string,
    componentId: string,
    workspace: string
) {
    return transport.patch<UpdateExperimentGraphRequest, SimulationGraphResponse>(
        `/api/filtering-simulation/experiments/${pathEscape(experimentId)}/graphs/${pathEscape(graphId)}`,
        data,
        {
            componentId,
            workspace,
        }
    );
}

export function getExperimentRunsForExperiment(experimentId: string, componentId: string, workspace: string) {
    return transport.get<SimulationExperimentRunResponse[]>(
        `/api/filtering-simulation/experiments/${pathEscape(experimentId)}/runs`,
        {
            workspace,
            componentId,
        }
    );
}

export function createExperimentRun(
    data: CreateExperimentRunRequest,
    experimentId: string,
    componentId: string,
    workspace: string
) {
    return transport.post<CreateExperimentRunRequest, SimulationExperimentRunResponse>(
        `/api/filtering-simulation/experiments/${pathEscape(experimentId)}/runs`,
        data,
        {
            componentId,
            workspace,
        }
    );
}

export function getSimulationRuns(componentId: string, workspace: string) {
    return transport.get<GetSimulationRunsResponse>(`/api/filtering-simulation/experiment-runs`, {
        workspace,
        componentId,
    });
}

export function getSimulationRun(runId: string, componentId: string, workspace: string) {
    return transport.get<SimulationRunResponse>(`/api/filtering-simulation/experiment-runs/${pathEscape(runId)}`, {
        workspace,
        componentId,
    });
}

export function getPartnerProducts(componentId: string, workspace: string) {
    return transport.get<GetSimulationPartnerProductType>(`/api/filtering-simulation/products`, {
        workspace,
        componentId,
    });
}

export function getApplicationsReport(runId: string, componentId: string, workspace: string) {
    return transport.get<GetApplicationsReportResponse>(
        `/api/filtering-simulation/experiment-runs/${pathEscape(runId)}/reports/applications`,
        {
            workspace,
            componentId,
        }
    );
}
export function getFunnelRateReport(runId: string, componentId: string, workspace: string) {
    return transport.get<GetFunnelRateReportResponse>(
        `/api/filtering-simulation/experiment-runs/${pathEscape(runId)}/reports/funnel-rate`,
        {
            workspace,
            componentId,
        }
    );
}
export function getUniqueVolumeChangeReport(runId: string, componentId: string, workspace: string) {
    return transport.get<GetUniqueVolumeChangeReportResponse>(
        `/api/filtering-simulation/experiment-runs/${pathEscape(runId)}/reports/unique-volume-change`,
        {
            workspace,
            componentId,
        }
    );
}
export function getSegmentsReport(runId: string, componentId: string, workspace: string) {
    return transport.get<GetSegmentsReportResponse>(
        `/api/filtering-simulation/experiment-runs/${pathEscape(runId)}/reports/segments`,
        {
            workspace,
            componentId,
        }
    );
}
export function getResultsPerProductReport(runId: string, componentId: string, workspace: string) {
    return transport.get<GetResultsPerProductReportResponse>(
        `/api/filtering-simulation/experiment-runs/${pathEscape(runId)}/reports/results-per-product`,
        {
            workspace,
            componentId,
        }
    );
}
export function getExperimentRunProgressReport(runId: string, componentId: string, workspace: string) {
    return transport.get<GetExperimentRunProgressReportResponse>(
        `/api/filtering-simulation/experiment-runs/${pathEscape(runId)}/reports/experiment-run-progress`,
        {
            workspace,
            componentId,
        }
    );
}

export function deleteExperimentRun(experimentRunId: string, componentId: string, workspace: string) {
    return transport.delete<void>(`/api/filtering-simulation/experiment-runs/${experimentRunId}`, {
        workspace,
        componentId,
    });
}

export function deleteExperimentGraph(experimentId: string, graphId: string, componentId: string, workspace: string) {
    return transport.delete<void>(`/api/filtering-simulation/experiments/${experimentId}/graphs/${graphId}`, {
        workspace,
        componentId,
    });
}

export function cloneExperiment(experimentId: string, name: string, componentId: string, workspace: string) {
    return transport.post<CloneExperimentRequest, CloneExperimentResponse>(
        `/api/filtering-simulation/experiments/${pathEscape(experimentId)}/clone`,
        {
            name,
        },
        {
            componentId,
            workspace,
        }
    );
}

export function deleteExperiment(experimentId: string, componentId: string, workspace: string) {
    return transport.delete<void>(`/api/filtering-simulation/experiments/${pathEscape(experimentId)}`, {
        componentId,
        workspace,
    });
}
