import { IconMap } from '@apps/admin-portal-web/src/components/Icons';

export class PageHeader {
    title: string;
    icon?: IconMap;

    constructor(title: string, icon?: IconMap) {
        this.title = title;
        this.icon = icon;
    }
}
