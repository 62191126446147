import { useState } from 'react';
import { Button } from '@packages/shadcn/ui/button';
import { Dialog, DialogContent, DialogFooter } from '@packages/shadcn/ui/dialog';
import { ErrorComponentBoundary } from './ErrorComponentBoundary';

export function ErrorBoundary() {
    const [open, setOpen] = useState(true);

    return (
        <Dialog open={open} data-testid="error-page">
            <DialogContent>
                <ErrorComponentBoundary />
                <DialogFooter>
                    <Button type="button" onClick={() => setOpen(false)}>
                        Close
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
