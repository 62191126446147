import React from 'react';

type DataType = {
    color: MiniGraphBarColors;
    values: (number | null)[];
}[];

type MiniGraphBarProps = {
    id?: string;
    style?: React.CSSProperties;
    className?: string;
    'data-testid'?: string;
    data: DataType;
};

export const MINI_GRAPH_COLORS = {
    AMBER: 'amber',
    BLUE: 'blue',
    CYAN: 'cyan',
    FUCHSIA: 'fuchsia',
    RED: 'red',
    TEAL: 'teal',
    GREEN: 'green',
    GRAY: 'gray',
} as const;

export type MiniGraphBarColors = (typeof MINI_GRAPH_COLORS)[keyof typeof MINI_GRAPH_COLORS];

export const barColors: { [key in MiniGraphBarColors]: [string, string, string] } = {
    [MINI_GRAPH_COLORS.AMBER]: ['bg-amber-500', 'bg-amber-300', 'bg-amber-100'],
    [MINI_GRAPH_COLORS.BLUE]: ['bg-blue-500', 'bg-blue-300', 'bg-blue-100'],
    [MINI_GRAPH_COLORS.CYAN]: ['bg-cyan-500', 'bg-cyan-300', 'bg-cyan-100'],
    [MINI_GRAPH_COLORS.FUCHSIA]: ['bg-fuchsia-500', 'bg-fuchsia-300', 'bg-fuchsia-100'],
    [MINI_GRAPH_COLORS.RED]: ['bg-red-500', 'bg-red-300', 'bg-red-100'],
    [MINI_GRAPH_COLORS.TEAL]: ['bg-teal-500', 'bg-teal-300', 'bg-teal-100'],
    [MINI_GRAPH_COLORS.GREEN]: ['bg-green-500', 'bg-green-300', 'bg-green-100'],
    [MINI_GRAPH_COLORS.GRAY]: ['bg-gray-500', 'bg-gray-300', 'bg-gray-100'],
} as const;

const getMaxValue = (data: DataType) => {
    let maxValue = 0;
    // in the loop we do not need to check for undefined when indexing
    // the arrays as we are sure that they are within bounds, therefore
    // we explicitly say that they are not undefined with the exclamation
    // mark (!).
    for (let i = 0; i < data.length; i++) {
        const values = data[i]!.values as number[];
        for (let j = 0; j < values.length; j++) {
            if (values[j]! > maxValue) {
                maxValue = values[j]!;
            }
        }
    }
    return maxValue;
};

export const MiniGraphBar = ({ id, data, style, className, 'data-testid': dataTestid }: MiniGraphBarProps) => {
    const maxValue = getMaxValue(data);

    return (
        <div id={id} data-testid={dataTestid} style={style} className={[className, 'flex h-full gap-[8%]'].join(' ')}>
            {data.map((dataItem, index) => {
                return (
                    <div key={index} className="flex flex-1 gap-[10%]">
                        {(dataItem.values || []).map((value, index) => {
                            let barHeightPercentage = 0;
                            if (value !== null) {
                                barHeightPercentage = (value * 100) / maxValue;
                            }

                            return (
                                <div
                                    key={index}
                                    style={{ height: `${barHeightPercentage}%` }}
                                    className={`${barColors[dataItem.color][index]} flex-1 self-end rounded-sm`}
                                />
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};
