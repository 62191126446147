import { type ApiErrorInterface } from '@apps/admin-portal-web/src/types/error';
import { camelToSnake } from '@apps/admin-portal-web/src/utils/camelToSnake';
import { snakeToCamel } from '@apps/admin-portal-web/src/utils/snakeToCamel';

// This comment is required for eslint to understand that RequestInit exists.
/* global RequestInit */

interface Headers {
    workspace?: string;
    componentId?: string;
}

const ApiError = function (response: Response, workspace?: string, componentId?: string): ApiErrorInterface {
    const error = new Error(response.statusText) as ApiErrorInterface;
    error.response = response;
    error.workspace = workspace;
    error.componentId = componentId;
    error.type = 'ApiError';
    return error;
};

/**
 * Performs a HTTP request
 */
export function fetchT<RequestBodyType, ResponseBodyType>(
    url: string,
    { body, ...customConfig }: { body?: RequestBodyType; method: string },
    headers: Headers = {}
): Promise<ResponseBodyType> {
    const { workspace, componentId } = headers;

    const config: RequestInit = {
        ...customConfig,
        headers: {
            ...(body && { 'Content-Type': 'application/json' }),
            ...(workspace && { 'x-workspace-id': workspace }),
            ...(componentId && { 'x-workspace-component-id': componentId }),
        },
    };

    if (body) {
        const snakedBody = camelToSnake(body);
        config.body = JSON.stringify(snakedBody);
    }

    return fetch(url, config).then(async response => {
        if (response.ok) {
            const text = await response.text();
            const json = text === '' ? {} : snakeToCamel(JSON.parse(text));
            return json as ResponseBodyType;
        } else {
            throw ApiError(response, workspace, componentId);
        }
    });
}

export const transport = {
    /**
     * Performs a get request
     */
    get<ResponseBodyType>(url: string, headers?: Headers): Promise<ResponseBodyType> {
        return fetchT(url, { method: 'GET' }, headers);
    },

    /**
     * Performs a post request
     */
    post<RequestBodyType, ResponseBodyType>(
        url: string,
        data: RequestBodyType,
        headers?: Headers
    ): Promise<ResponseBodyType> {
        return fetchT(url, { method: 'POST', ...(data && { body: data }) }, headers);
    },

    /**
     * Performs a put request
     */
    put<RequestBodyType, ResponseBodyType>(
        url: string,
        data?: RequestBodyType,
        headers?: Headers
    ): Promise<ResponseBodyType> {
        return fetchT(url, { method: 'PUT', ...(data && { body: data }) }, headers);
    },

    /**
     * Performs a patch request
     */
    patch<RequestBodyType, ResponseBodyType>(
        url: string,
        data: RequestBodyType,
        headers?: Headers
    ): Promise<ResponseBodyType> {
        return fetchT(url, { method: 'PATCH', ...(data && { body: data }) }, headers);
    },

    /**
     * Performs a delete request
     */
    delete<ResponseBodyType>(url: string, headers?: Headers): Promise<ResponseBodyType> {
        return fetchT(url, { method: 'DELETE' }, headers);
    },
};
