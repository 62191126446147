import { type RouteObject } from 'react-router-dom';
import { PageHeader } from '@apps/admin-portal-web/src/types/page-header';
import { FAQ_PAGES } from '@apps/admin-portal-web/src/types/faq';
import componentRoutes from './component/componentRoutes';
import { workspaceUsersLoader } from './workspace-users/loader';
import { editWorkspaceUsersLoader } from './workspace-users/edit/loader';
import { workspaceRoutesLoader } from './loader';
import { ErrorComponentBoundary as ErrorBoundary } from '../ErrorComponentBoundary';

const dashboardRoute: RouteObject = {
    path: 'dashboard?',
    children: [
        {
            index: true,
            lazy: () => import('@apps/admin-portal-web/src/pages/workspace/dashboard'),
            handle: {
                getPageHeader: () => new PageHeader('Active component instances', 'instances'),
                faqPage: FAQ_PAGES.DASHBOARD,
            },
            ErrorBoundary,
        },
    ],
};

const usersRoutes: RouteObject[] = [
    {
        path: 'workspace-users',
        loader: workspaceUsersLoader,
        lazy: () => import('@apps/admin-portal-web/src/pages/workspace/workspace-users'),
        handle: {
            getPageHeader: () => new PageHeader('Users', 'workspace-users'),
            faqPage: FAQ_PAGES.WORKSPACE_USERS,
        },
        ErrorBoundary,
    },
    {
        path: 'workspace-users/:userId',
        loader: editWorkspaceUsersLoader,
        lazy: () => import('@apps/admin-portal-web/src/pages/workspace/workspace-users/edit'),
        handle: {
            getPageHeader: () => new PageHeader('Edit User', 'workspace-users'),
            faqPage: FAQ_PAGES.WORKSPACE_USER,
        },
        ErrorBoundary,
    },
];

const workspaceRoute: RouteObject = {
    id: 'workspace',
    lazy: () => import('@apps/admin-portal-web/src/pages/workspace'),
    loader: workspaceRoutesLoader,
    path: ':workspace',
    shouldRevalidate: data => {
        const { currentParams, nextParams, defaultShouldRevalidate } = data;
        // revalidate if the revalidate function was called or if the component id or workspace has changed
        return (
            defaultShouldRevalidate ||
            currentParams?.componentID !== nextParams?.componentID ||
            currentParams?.workspace !== nextParams?.workspace
        );
    },
    children: [dashboardRoute, ...usersRoutes, ...componentRoutes],
};

const workspaceRoutes: RouteObject[] = [workspaceRoute];
export default workspaceRoutes;
