export const OPERATORS = {
    EQUAL: '=',
    NOT_EQUAL: '!=',
    LOWER_THAN: '<',
    LOWER_EQUAL_THAN: '<=',
    GREATER_THAN: '>',
    GREATER_EQUAL_THAN: '>=',
    ONE_OF: 'one_of',
    NOT_ONE_OF: 'not_one_of',
    MISSING: 'missing',
    HAS_PREFIX: 'has_prefix',
    HAS_NOT_PREFIX: 'has_not_prefix',
} as const;

export const APPLICANT_SELECTOR = {
    MAIN: 'main',
    CO: 'co',
    ALL: 'all',
    ANY: 'any',
} as const;
