import { type LoaderFunctionArgs } from 'react-router';
import {
    getExperimentById,
    getExperimentFilterRules,
    getExperimentGraphById,
} from '@apps/admin-portal-web/src/actions/simulation';
import { type LoaderData } from '@apps/admin-portal-web/src/types/loader';
import { mapFilters } from '@apps/admin-portal-web/src/utils/parsers';
import { getClaims } from '@apps/admin-portal-web/src/utils/cookies';
import { FilterRule } from '@packages/filter-graph/src/types';

export async function simulationExperimentsDetailGraphLoader({ params }: LoaderFunctionArgs) {
    const auth = getClaims();
    if (!auth) {
        throw new Error('no auth claims found');
    }

    const workspace = params.workspace as string;
    const componentId = params.componentID as string;
    const experimentId = params.experimentId as string;
    const graphId = params.graphId as string;

    const [graph, filterRules, experiment] = await Promise.all([
        getExperimentGraphById(experimentId, graphId, componentId, workspace),
        getExperimentFilterRules(experimentId, componentId, workspace),
        getExperimentById(experimentId, componentId, workspace),
    ]);
    const convertedFilterRules: FilterRule[] = filterRules.map((rule): FilterRule => {
        return {
            id: rule.id,
            name: rule.propertyName,
            label: rule.label,
            description: rule.description,
            applicantFilter: rule.applicantFilterRule,
            valueType: rule.valueType,
            options: rule.options,
        };
    });

    const mappedFilters = mapFilters(convertedFilterRules);

    return { componentId, experimentId, workspace, graph, filterRules: mappedFilters, experiment };
}

export type SimulationExperimentsDetailGraphLoader = LoaderData<typeof simulationExperimentsDetailGraphLoader>;
