import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router';
import { createBrowserRouter as reactCreateBrowserRouter } from 'react-router-dom';
import pJson from '../../package.json';
import { env } from './env';

let createBrowserRouter = reactCreateBrowserRouter;

if (env.VITE_SENTRY_DSN) {
    Sentry.init({
        dsn: env.VITE_SENTRY_DSN,
        release: `${pJson.name}@${pJson.version}`,
        environment: env.ENVIRONMENT || 'local',
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                ),
            }),
            new Sentry.Replay(),
        ],
        tracesSampleRate: 1.0,
    });

    createBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
}

export const sentryCreateBrowserRouter = createBrowserRouter;
