import type { FilterRule, Graph, Product, ProductEvent } from '@packages/filter-graph/src/types';
import { type FilterRequestType } from '@apps/admin-portal-web/src/types/filtering-request';
import { type FilterResultType } from '@apps/admin-portal-web/src/types/filtering-results';
import { camelToSnakeSearch } from '@apps/admin-portal-web/src/utils/searchParams';
import { createSearchParams } from 'react-router-dom';
import { Optional } from '@apps/admin-portal-web/src/types/utils';
import { transport } from './transport';
import { pathEscape } from '../utils/pathEscape';

export function getGraphById(graphId: string, componentId: string) {
    return transport.get<Graph>(`/api/product-filtering/graphs/${pathEscape(graphId)}`, { componentId });
}

export function getGraphs(productId: string, componentId: string) {
    const query = createSearchParams({
        product_id: productId,
    }).toString();

    return transport.get<Graph[]>(`/api/product-filtering/graphs?${query}`, {
        componentId,
    });
}

export function createGraph(body: Optional<Graph, 'id' | 'active'>, comment: string | undefined, componentId: string) {
    interface CreateGraphRequest extends Optional<Graph, 'id' | 'active'> {
        comment: string | undefined;
    }
    return transport.post<CreateGraphRequest, Graph>(
        `/api/product-filtering/graphs`,
        { ...body, comment },
        { componentId }
    );
}

export function getFilterRules(componentId: string) {
    return transport.get<FilterRule[]>(`/api/product-filtering/filters`, { componentId });
}

export function createFilterRule(body: FilterRule, componentId: string) {
    return transport.post<FilterRule, FilterRule>('/api/product-filtering/filters', body, { componentId });
}

export function updateFilterRule(name: string, body: FilterRule, componentId: string) {
    return transport.patch<FilterRule, FilterRule>(`/api/product-filtering/filters/${pathEscape(name)}`, body, {
        componentId,
    });
}

export function getFilterRuleProducts(componentId: string) {
    return transport.get<Product[]>('/api/product-filtering/products', { componentId });
}

export function getProduct(id: string, componentId: string) {
    return transport.get<Product>(`/api/product-filtering/products/${pathEscape(id)}`, { componentId });
}

export function getFilteringRequests(componentId: string, applicationId: string = '', filteringRequestId: string = '') {
    // remove falsy values from requests
    const queryString = Object.entries({ applicationId, id: filteringRequestId }).reduce(
        (acc, [key, value]) => (value ? { ...acc, [key]: value } : acc),
        {}
    );
    return transport.get<FilterRequestType[]>(
        `/api/product-filtering/filtering-requests?${camelToSnakeSearch(queryString)}`,
        {
            componentId,
        }
    );
}

export function getFilteringResults(componentId: string, applicationId: string, applicationRevision: number) {
    return transport.get<FilterResultType[]>(
        `/api/product-filtering/results?${camelToSnakeSearch({ applicationId, applicationRevision })}`,
        { componentId }
    );
}

export function validateRule(name: string, componentId: string) {
    interface ValidateRuleRequest {
        name: string;
    }
    interface ValidateRuleResponse {
        valid: boolean;
        errors: string[];
        warnings: string[];
        matchingProperty: null | {
            name: string;
            suggestedValueTypes: string[];
            applicantFilter: boolean;
            samples: any[];
        };
    }
    return transport.post<ValidateRuleRequest, ValidateRuleResponse>(
        '/api/product-filtering/filters/validate-name',
        { name },
        { componentId }
    );
}

export function updateProductFilteringEnabled(
    productId: string,
    disabled: boolean,
    comment: string,
    componentId: string
) {
    interface updateProductFilteringEnabledRequest {
        comment: string | undefined;
    }

    const operation = disabled ? 'disable-filtering' : 'enable-filtering';
    return transport.post<updateProductFilteringEnabledRequest, void>(
        `/api/product-filtering/products/${pathEscape(productId)}/${pathEscape(operation)}`,
        { comment },
        { componentId }
    );
}

export function getProductEvents(productId: string, componentId: string) {
    return transport.get<ProductEvent[]>(`/api/product-filtering/products/${pathEscape(productId)}/events`, {
        componentId,
    });
}
