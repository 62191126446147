import { type HTMLAttributes } from 'react';
import { Copy } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@packages/shadcn/ui/tooltip';
import { Badge } from '@packages/shadcn/ui/badge';
import { cn } from '@packages/shadcn/lib/utils';
import { useCopyToClipboard } from '@apps/admin-portal-web/src/hooks/useCopyToClipboard';

interface CopyValueProps extends HTMLAttributes<HTMLDivElement> {
    variant?: 'default' | 'secondary' | 'destructive' | 'outline' | null | undefined;
    value: string;
    displayValue?: string;
    label: string;
    type?: 'uuid' | null | undefined;
}

export function CopyValue({ type, label, displayValue, value, variant, className }: CopyValueProps) {
    const copyToClipboard = useCopyToClipboard();

    return (
        <TooltipProvider delayDuration={100}>
            <Tooltip>
                <TooltipTrigger className="w-fit">
                    <Badge
                        variant={variant}
                        className={cn('h-8 rounded-sm px-4 text-sm font-normal', className)}
                        onClick={() => copyToClipboard({ value, label })}
                    >
                        <p>{type === 'uuid' ? value?.split('-')[0] : displayValue || value}</p>
                        <Copy className="ml-4 h-4 w-4" />
                    </Badge>
                </TooltipTrigger>
                <TooltipContent side="bottom" className="flex flex-col gap-1">
                    <div className="flex flex-row justify-between border-b font-semibold italic">
                        <p>{label}</p>
                        <p>Copy to clipboard</p>
                    </div>
                    <p className="font-semibold">{value}</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
}
