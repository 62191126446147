import {
    WorkspaceComponentInstance,
    type WorkspaceComponentInstanceApi,
} from '@apps/admin-portal-web/src/types/components';
import { transport } from './transport';
import { pathEscape } from '../utils/pathEscape';

export function getWorkspaceComponents(workspace: string) {
    return transport.get<WorkspaceComponentInstanceApi[]>(`/api/workspaces/${pathEscape(workspace)}/components`);
}

export function createWorkspaceComponentInstance(workspace: string, data: WorkspaceComponentInstanceApi) {
    interface CreateWorkspaceComponentInstanceRequest extends WorkspaceComponentInstanceApi {
        workspaceName: string;
    }

    return transport.post<CreateWorkspaceComponentInstanceRequest, WorkspaceComponentInstance>(
        `/api/workspaces/${pathEscape(workspace)}/components`,
        { ...data, workspaceName: workspace },
        { workspace }
    );
}

export function editWorkspaceComponentInstance(
    workspace: string,
    data: WorkspaceComponentInstanceApi,
    componentId: string
) {
    return transport.patch<WorkspaceComponentInstanceApi, WorkspaceComponentInstance>(
        `/api/workspaces/${pathEscape(workspace)}/components/${pathEscape(componentId)}`,
        data,
        { workspace, componentId }
    );
}
