import { GetSegmentsReportResponse } from '@apps/admin-portal-web/src/dto/filtering-simulation/runs';
import { MINI_GRAPH_COLORS } from '@apps/admin-portal-web/src/components/graphs/MiniGraphBar';
import { ApplicantSegmentsData } from '../cards/ApplicantSegmentsCard';

const SEGMENTS = {
    CAREFUL_CONNIE: '0',
    COLLECTING_CARL: '1',
    DREAMING_DANA: '2',
    OPTIMISTIC_OSCAR: '3',
    RETURNING_RACHEL: '4',
    NOT_PREDICTED: '5',
} as const;

const SEGMENTS_COLORS = {
    [SEGMENTS.CAREFUL_CONNIE]: MINI_GRAPH_COLORS.BLUE,
    [SEGMENTS.COLLECTING_CARL]: MINI_GRAPH_COLORS.TEAL,
    [SEGMENTS.DREAMING_DANA]: MINI_GRAPH_COLORS.AMBER,
    [SEGMENTS.OPTIMISTIC_OSCAR]: MINI_GRAPH_COLORS.RED,
    [SEGMENTS.RETURNING_RACHEL]: MINI_GRAPH_COLORS.GREEN,
    [SEGMENTS.NOT_PREDICTED]: MINI_GRAPH_COLORS.GRAY,
};
const segmentsLabel = {
    [SEGMENTS.CAREFUL_CONNIE]: 'Carefull Connie',
    [SEGMENTS.COLLECTING_CARL]: 'Collecting Carl',
    [SEGMENTS.DREAMING_DANA]: 'Dreaming Dana',
    [SEGMENTS.OPTIMISTIC_OSCAR]: 'Optimistic Oscar',
    [SEGMENTS.RETURNING_RACHEL]: 'Returning Rachel',
    [SEGMENTS.NOT_PREDICTED]: 'Not Predicted',
};

const SEGMENTS_ORDER = [
    SEGMENTS.CAREFUL_CONNIE,
    SEGMENTS.COLLECTING_CARL,
    SEGMENTS.DREAMING_DANA,
    SEGMENTS.OPTIMISTIC_OSCAR,
    SEGMENTS.RETURNING_RACHEL,
    SEGMENTS.NOT_PREDICTED,
];

export const parseSegmentsResponse = (data: GetSegmentsReportResponse): ApplicantSegmentsData => {
    return {
        nrOfProducts: data.nrOfProducts,
        segmentsData: SEGMENTS_ORDER.map(segment => {
            const baselineApproves = data.segments[segment]?.nrOfBaselineApproves ?? 0;
            const simulationApproves = data.segments[segment]?.nrOfSimulationApproves ?? 0;
            const applications = data.segments[segment]?.nrOfApplications ?? 1;
            return {
                label: segmentsLabel[segment],
                color: SEGMENTS_COLORS[segment],
                baseline: baselineApproves / applications,
                simulation: simulationApproves / applications,
            };
        }),
    };
};
