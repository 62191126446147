import { type SectionValueType } from './sidebar';
import { SnakeToCamelCase } from './utils';

export type WorkspaceComponentInstance = {
    id: string;
    component: SectionValueType;
    name: string;
    description?: string;
    pathname: string;
    partnerProductsWorkspaceComponentId?: string;
    filteringSimulationWorkspaceComponentId?: string;
};

export type WorkspaceComponentInstanceApi = {
    id: string;
    component: SectionValueType;
    name: string;
    description?: string;
    relations?: {
        [key in SnakeToCamelCase<SectionValueType>]?: string[];
    };
};

export const COMPONENT_TYPES = {
    PRODUCTS: 'partner_products',
    FILTERING: 'product_filtering',
    FILTERING_SIMULATION: 'filtering_simulation',
} as const;

export const COMPONENT_TYPE_SEARCH_PATH = {
    [COMPONENT_TYPES.PRODUCTS]: 'partner-products',
    [COMPONENT_TYPES.FILTERING]: 'product-filtering',
    [COMPONENT_TYPES.FILTERING_SIMULATION]: 'filtering-simulation',
} as const;
