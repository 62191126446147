import { OktaAuth } from '@okta/okta-auth-js';
import { env } from '@apps/admin-portal-web/src/configs/env';
import { SigninWithRedirectOptions } from '@okta/okta-auth-js/types/lib/oidc/types/options';

export const oktaRedirectUri = window.location.origin + '/login';

export function signInWithRedirect(nameHint: string, emailHint: string): Promise<void> {
    const oktaIssuer = env.OKTA_ISSUER;
    const oktaClientId = env.OKTA_CLIENT_ID;

    if (!oktaClientId) {
        throw new Error('okta client id not set');
    }

    const oktaAuth = new OktaAuth({
        issuer: oktaIssuer,
        clientId: oktaClientId,
        redirectUri: oktaRedirectUri,
        responseMode: 'query',
        responseType: 'code',
        pkce: false,
        devMode: true,
    });

    const opts: SigninWithRedirectOptions = {};
    if (nameHint || emailHint) {
        opts.extraParams = {
            name_hint: nameHint,
            email_hint: emailHint,
        };
    }

    return oktaAuth.signInWithRedirect(opts);
}
