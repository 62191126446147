import { type RouteObject } from 'react-router-dom';
import { Home } from './index';

const homeRoute: RouteObject = {
    index: true,
    Component: Home,
};

const homeRoutes: RouteObject[] = [homeRoute];
export default homeRoutes;
