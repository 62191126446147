// pathEscape implements RFC 3986 §3.3
export function pathEscape(v: string): string {
    if (v === '' || typeof v == 'undefined' || v === null) {
        return '';
    }

    const shouldEscape = (c: string): boolean => {
        if ((c >= 'a' && c <= 'z') || (c >= 'A' && c <= 'Z') || (c >= '0' && c <= '9')) return false;
        switch (c) {
            case '-':
            case '_':
            case '.':
            case '~':
                return false;

            case '$':
            case '&':
            case '+':
            case ':':
            case '=':
            case '@':
                return false;

            case '/':
            case ';':
            case ',':
            case '?':
                return true;
        }
        return true;
    };

    let ret: string = '';
    for (let i = 0; i < v.length; i++) {
        if (shouldEscape(v[i] as string)) {
            const encoded = encodeURIComponent(v[i] as string);
            if (encoded == v[i]) {
                ret += `%${v.charCodeAt(i).toString(16).toUpperCase()}`;
            } else {
                ret += encodeURIComponent(v[i] as string);
            }
        } else {
            ret += v[i];
        }
    }

    return ret;
}
