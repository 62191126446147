export const redirectUrlFromString = (url: string): string | null => {
    const parsed = new URL(url);
    return redirectUrlFromUrl(parsed);
};

export const redirectUrlFromUrl = (url: Location | URL): string | null => {
    const ret = `${url.pathname}${url.search}${url.hash}`;
    if (ret == '/' || ret.startsWith('/login')) {
        return null;
    }
    return ret;
};
