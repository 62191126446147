import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { env } from '@apps/admin-portal-web/src/configs/env';
import * as Sentry from '@sentry/react';
import { App } from './App';
import pJson from '../package.json';
import './index.css';

console.info('%c Build version: ', 'background-color:green;color:white;', env.VITE_BUILD_VERSION, `${pJson.version}`);

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);
const AppWithProfiling = Sentry.withProfiler(App);

root.render(
    <StrictMode>
        <AppWithProfiling />
    </StrictMode>
);
