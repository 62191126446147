import { type LoaderFunctionArgs } from 'react-router';
import { getWorkspaceUsers } from '@apps/admin-portal-web/src/actions/users';
import { type LoaderData } from '@apps/admin-portal-web/src/types/loader';
import { getClaims } from '@apps/admin-portal-web/src/utils/cookies';

export async function workspaceUsersLoader({ params }: LoaderFunctionArgs) {
    const auth = getClaims();
    if (!auth) {
        throw new Error('no auth claims found');
    }

    const workspace = params.workspace as string;
    const users = await getWorkspaceUsers(workspace);
    return { users, workspace };
}

export type WorkspaceUsersLoader = LoaderData<typeof workspaceUsersLoader>;
