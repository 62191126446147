import { Navigate, Outlet } from 'react-router-dom';
import { useClaims } from '@apps/admin-portal-web/src/hooks/useClaims';
import { Toaster } from '@apps/admin-portal-web/src/components/Toaster';

export const UnauthenticatedLayout = () => {
    try {
        useClaims();
        return <Navigate to="/" />;
    } catch {
        // `useClaims()` call failed, continue and render the component (and its children).
    }

    // Update the page title.
    document.title = 'Lendo Portal - Login';

    return (
        <div className="h-screen">
            <Outlet />
            <Toaster />
        </div>
    );
};
