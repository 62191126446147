// export type CamelizeKeys<T> = T extends object ? { [key in keyof T as key extends string ? Camelize<key> : key]: T[key] extends object ? CamelizeKeys<T[key]> : T[key] } : T
export type CamelizeKeys<T extends object | undefined | null> = T extends undefined
    ? undefined
    : T extends null
    ? null
    : T extends Array<infer ArrayType>
    ? ArrayType extends object
        ? Array<CamelizeKeys<ArrayType>>
        : Array<ArrayType>
    : T extends Uint8Array
    ? Uint8Array
    : T extends Date
    ? Date
    : {
          [K in keyof T as ToCamel<K>]: T[K] extends Array<infer ArrayType> | undefined | null
              ? ArrayType extends object
                  ? Array<CamelizeKeys<ArrayType>>
                  : Array<ArrayType>
              : T[K] extends object | undefined | null
              ? CamelizeKeys<T[K]>
              : T[K];
      };
export type ToCamel<S extends string | number | symbol> = S extends string
    ? S extends `${infer Head}_${infer Tail}`
        ? `${ToCamel<Uncapitalize<Head>>}${Capitalize<ToCamel<Tail>>}`
        : S extends `${infer Head}-${infer Tail}`
        ? `${ToCamel<Uncapitalize<Head>>}${Capitalize<ToCamel<Tail>>}`
        : Uncapitalize<S>
    : never;

export function snakeToCamel<T extends object | undefined | null>(obj: T): CamelizeKeys<T>;
export function snakeToCamel<T extends object | undefined | null>(obj: T[]): CamelizeKeys<T>[];
export function snakeToCamel(obj: unknown): unknown {
    if (typeof obj !== 'object' || obj === null || obj === undefined) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(snakeToCamel);
    }

    const camelObj = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const camelKey = snakeToCamelString(key);
            //@ts-ignore
            camelObj[camelKey] = snakeToCamel(obj[key]);
        }
    }

    return camelObj;
}

export const snakeToCamelString = <T extends string>(value: T): ToCamel<T> => {
    return value.replace(/_([a-z])/g, (_, p1) => p1.toUpperCase()) as ToCamel<T>;
};

export const camelCaseString = <T extends string>(str: T): ToCamel<T> =>
    str
        .replaceAll('_', ' ')
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, '') as ToCamel<T>;
