import { type AuthTokenClaims } from '@apps/admin-portal-web/src/types/auth';

// This is just for the IDE to work correctly with typescript.
// It should have no effect on any code.
declare global {
    interface Window {
        Cypress:
            | {
                  AuthClaims: AuthTokenClaims | undefined;
              }
            | undefined;
    }
}

export function getClaims(): AuthTokenClaims | null {
    // Using setCookie doesn't always work in cypress. Read mocked auth claims from a window property instead.
    if (window.Cypress && window.Cypress.AuthClaims) {
        return window.Cypress.AuthClaims;
    }

    const claims =
        document.cookie
            .split(';')
            .find(cookie => cookie.includes('jwt-claims='))
            ?.replace('jwt-claims=', '')
            .replace(/%3D/g, '') || null;

    if (!claims || claims === ' ') {
        return null;
    } else {
        return JSON.parse(atob(claims)) as AuthTokenClaims;
    }
}
